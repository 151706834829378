import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class Globals {
  readonly phoneTollFree: string = '888-643-3133';
  readonly phoneLocal: string = '949-502-0050';
  readonly phoneEmergency: string = '949-216-0438';
  readonly fax: string = '949-502-0048';

  readonly mainAddress: string = '19200 Von Karman Ave. Suite 150 Irvine, CA 92612';
  readonly derickKannAddress : string = '1250 S. Capital of Texas Hwy Bldg 3 Suite 400 Austin, TX 78746';

  readonly stockMarketYears: number = new Date().getFullYear() - 1928 ;

  readonly formADVUrl: string = 'https://adviserinfo.sec.gov/firm/summary/109143';
  
  readonly amazonIndexFundsTwelveStepBookUrl : string = 'https://www.amazon.com/Index-Funds-12-Step-Recovery-Investors/dp/0976802317/';
  readonly amazonPrimeVideoIndexFundsMovieUrl: string = 'https://www.amazon.com/Index-Funds-12-Step-Recovery-Investors/dp/B01M2YZUFN/';
  readonly amazonDVDIndexFundsMovieUrl: string = 'https://www.amazon.com/Index-Funds-12-Step-Recovery-Investors/dp/0692696679/';
  readonly amazonInvestingKitUrl: string = 'https://www.amazon.com/dp/B07VKZTQTT/';
  readonly amazonGaltonBoard : string = 'https://www.amazon.com/Four-Pines-Publishing-Inc-Galton/dp/B078Y7RN6Y';
  readonly shopifyGaltonBoard: string = 'https://store.fourpines.com/collections/frontpage/products/galton-board-stock-market-edition';
  readonly shopifyPlayingCards: string = 'https://www.amazon.com/dp/B0BNCCWVV7?ref_=cm_sw_r_mwn_dp_SAVRSTYFWS3V69MTFN4X';
  readonly googlePlayIndexFundsMovieUrl: string = 'https://play.google.com/store/movies/details/Index_Funds_The_12_Step_Recovery_Program_for_Activ?id=f2_tNwb6aO4';

  hideHeaderFooter = false;

  webp =  document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') == 0;
  printing:EventEmitter<boolean>=new EventEmitter();
  isLoggedIn =false;
  signInModalEvents:EventEmitter<any>=new EventEmitter();


  constructor(){
    //console.log("GLOBALS RUNS");    
  }



}