import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { MasterGuard } from './guards/master.guard';

const routes: Routes = [
{path:'',component:AppComponent,children:[
  {path:'', loadChildren:() => import('./pages/pages.module').then(m => m.PagesModule)}
]},
{ 
  path:"survey",
  loadChildren:() => import('./pages/survey/survey.module').then(m => m.SurveyModule), canActivate:[MasterGuard]
}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
